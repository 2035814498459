import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import GroupModule from "@/store/module/shared/GroupModule";
import Blocks from "@/components/shared/Blocks.vue";
import UserModule from "@/store/module/user/UserModule";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
let GroupsPage = class GroupsPage extends Vue {
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get allGroups() {
        return this.$store.getters[GroupModule.types.getters.GROUPS];
    }
    get groups() {
        return this.allGroups.filter((g) => !g.isPremoderated);
    }
    get groupsBlocks() {
        const items = [];
        for (let group of this.groups) {
            items.push({
                image: `${IMAGE_SERVER_URL}/public/${group.logo}`,
                href: `/user/groups/${group.id}`,
                title: group.shortName ? group.shortName : group.fullName,
            });
        }
        return items;
    }
    async mounted() {
        if (this.groups.length === 0) {
            await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
            await this.$store.dispatch(GroupModule.types.actions.GET_GROUPS, { regionId: this.user.address.regionId });
        }
    }
};
GroupsPage = __decorate([
    Component({
        components: {
            Blocks,
        },
    })
], GroupsPage);
export default GroupsPage;
